<!--[style.background-image]="Sfondo"-->
<div [style.color]="FontColor"
     style="height: 100%; background-size: cover; background-position: center; background-repeat: no-repeat; margin: 0; padding: 0; color: white ">
  <dx-box direction="col" width="100%" height="100%" (onItemClick)="checkLostFocusBarcode($event)"
          style="margin: 0; padding: 0; color: white">

    <dxi-item class="rect" [baseSize]="70">

      <dx-box direction="row" width="100%" height="100%">
        <dxi-item [ratio]="1" class="rect">

          <div *ngIf="Votazioni.length>0 && actualStep>1" style="text-align: left; height: 100%; padding:0 15px">


            <dx-button height="60" width="60" style="margin-top:0; margin-bottom:0; padding:0"
                       class="movement-button-little-green circle-button" [text]='ProgressPercentage'>
            </dx-button>

            <b class="label-green" style="font-size:14px;">Completamento</b>

          </div>
          <div *ngIf="actualStep==1" style="text-align: left; height: 100%; padding:0 15px">


            <dx-button height="60" width="60" style="margin-top:0; margin-bottom:0; padding:0"
                       class="movement-button-little-gray circle-button">
            </dx-button>

          </div>

        </dxi-item>
        <dxi-item [ratio]="1" class="rect">

          <div style="text-align: center; height: 100%; vertical-align: top ">
            <img [attr.data-configid]="LogoConfigID"
                 [src]="_DomSanitizationService.bypassSecurityTrustUrl('data:image;base64,' + LogoConvocazione)"
                 alt="Assemblea"
                 style="object-fit: cover;  max-width: 300px;  max-height: 60px;" />
          </div>
        </dxi-item>
        <dxi-item [ratio]="1" class="rect">
          <div *ngIf="Votazioni.length>0 && actualStep>1" style="text-align: right; height: 100%; padding: 0 15px">

            <b class="label-blue" style="font-size:14px;">Tempo Rimasto</b>

            <dx-button height="60" width="60" style="margin-top:0; margin-bottom:0; padding:0"
                       class="movement-button-little-blue circle-button" [text]='TimeProgress'>
            </dx-button>

          </div>

          <div *ngIf="actualStep==1" style="text-align: right; height: 100%; padding: 0 15px">


            <dx-button height="60" width="60" style="margin-top:0; margin-bottom:0; padding:0"
                       class="movement-button-little-gray circle-button">
            </dx-button>

          </div>

        </dxi-item>
      </dx-box>
    </dxi-item>
    <dxi-item [ratio]="1">
      <dx-box direction="row" width="100%" height="100%">
        <dxi-item [baseSize]="25">  </dxi-item>
        <dxi-item class="rect" [ratio]="1" id="preferencesList">


          <div class="step1" style="width: 60%;  align-self: center; margin-top:5%;  " *ngIf="actualStep==1">

            <h2><b>INSERISCI IL BARCODE</b></h2>

            <div>
              <dx-text-box #textBoxBarcode [(value)]="Barcode" placeholder='BARCODE' (onEnterKey)="BarcodeInserted()" style="background-color:white;"></dx-text-box>
            </div>

            <dx-button #barcodeButton (click)="BarcodeInserted()" height="50" min-width="120"
                       style=" border-radius: 3px; padding: 3px 5px; margin: 25px; font-size: 18px; vertical-align: middle; "
                       class="movement-button-little-yellow" text='{{"LABEL_CONFERMA_POLL_BOOTH" | formatMessage}}'>
            </dx-button>

            <!--<h2><b>{{cabNumber}}</b></h2>-->

          </div>

          <div class="step2" *ngIf="Votazioni.length>0 && actualStep==2" style="height: 100%; width: 80%; margin: 25px 25px 5px 25px; align-self: center; ">


            <dx-box direction="col" width="100%" height="100%" style="background-color: white; color: black; ">
              <dxi-item class="rect" [baseSize]="70">
                Premere avanti per continuare...
              </dxi-item>
              <dxi-item class="rect" [ratio]="1">


                <dx-data-grid [dataSource]="Accounting"
                              [rowAlternationEnabled]="true"
                              [showBorders]="true"
                              [showColumnHeaders]="false"
                              height="100%">
                  <dxo-scrolling mode="virtual"
                                 [scrollByContent]="true"
                                 [scrollByThumb]="true"
                                 showScrollbar="always"></dxo-scrolling>

                  <dxi-column cellTemplate="NameCellTemplate"></dxi-column>
                  <div *dxTemplate="let d of 'NameCellTemplate'" style="font-size: 18px; padding: 5px; margin: 0;">
                    <span [class.text-bold]="d.data.vShareholder.PAID==CurrentPotentialAttendant.PAID">{{d.data.vShareholder.BusinessName}}</span>
                    <div *ngIf="ShowZoneMessage">Assemblea: {{d.data.vShareholder.DelegationZone}}</div>
                  </div>

                  <dxi-column *ngIf="ShowBirtDateCabina" cellTemplate="BirthDateCellTemplate"></dxi-column>
                  <div *dxTemplate="let d of 'BirthDateCellTemplate'" style="font-size: 18px; padding: 5px; margin: 0; text-align: center ">
                    <span [class.text-bold]="d.data.vShareholder.PAID==CurrentPotentialAttendant.PAID">{{d.data.vShareholder.BirthDate  | date:' dd-MM-yyyy'}}</span>
                  </div>

                  <dxi-column *ngIf="ShowRelIdCabina" cellTemplate="RelIdCellTemplate"></dxi-column>
                  <div *dxTemplate="let d of 'RelIdCellTemplate'" style="font-size: 18px; padding: 5px; margin: 0; text-align:center ">
                    <span [class.text-bold]="d.data.vShareholder.PAID==CurrentPotentialAttendant.PAID">{{GetIconDescriptionByRelId(d.data)}}</span>
                  </div>

                  <dxi-column *ngIf="ShowNosCellCabina" cellTemplate="NoSCellTemplate"></dxi-column>
                  <div *dxTemplate="let d of 'NoSCellTemplate'" style="font-size: 18px; padding: 5px; margin: 0; text-align: center">
                    <span [class.text-bold]="d.data.vShareholder.PAID==CurrentPotentialAttendant.PAID">{{d.data.vShareholder.NoS}}</span>
                  </div>

                </dx-data-grid>


              </dxi-item>
              <dxi-item class="rect" [baseSize]="100">


                <dx-box direction="row" width="100%" height="100%">

                  <dxi-item *ngIf="ShareholderEnabled" [baseSize]="200" style="border-top: 2px groove black; border-right: 2px groove black;">
                    <div style="text-align: center; vertical-align: middle; font-size: 22px; margin: 10px; height: 100%">
                      <b>
                        Totale Soci<br />
                        <span class="label-green">{{NumeroSoci}}</span>
                      </b>
                    </div>
                  </dxi-item>
                  <dxi-item *ngIf="!ShareholderEnabled" [baseSize]="200" style="border: 0; ">
                    &nbsp;
                  </dxi-item>
                  <dxi-item [ratio]="1">
                    <div *ngIf="ShowZoneMessage">
                      <label style="background-color:orange; font-size:20px"> Stai votando per l'assemblea di '{{CurrentPotentialAttendant.DelegationZone}}' </label>
                    </div>
                    &nbsp;
                  </dxi-item>
                  <dxi-item *ngIf="SharesEnabled" [baseSize]="250" style="border-top: 2px groove black; border-left: 2px groove black; ">
                    <div style="text-align:center; vertical-align:middle; align-content:center; align-items:center; align-self:center; font-size:22px; margin:10px; height:100%">
                      <b>
                        {{SharesLabel}}<br />
                        <span class="label-orange">{{NumeroAzioni}}</span>
                      </b>
                    </div>
                  </dxi-item>
                  <dxi-item *ngIf="!SharesEnabled" [baseSize]="250" style="border: 0; ">
                    &nbsp;
                  </dxi-item>



                </dx-box>


              </dxi-item>
            </dx-box>





          </div>

          <div class="stepVot" *ngFor="let votazione of Votazioni"
               [style.display]="actualStep>2 && Votazioni.length>actualStep-3 && votazione.PEID==Votazioni[actualStep-3].PEID ? '' : 'none'"
               style="height: 100%; width: 100%; margin: 2px 15px 0 15px; align-self: center; ">



            <dx-box *ngIf="actualStep>2 && Votazioni.length>actualStep-3 && votazione.PEID==Votazioni[actualStep-3].PEID"
                    direction="col" width="100%" height="100%" style="background-color: white; color: black;margin:0; padding:0;
          align-content: center; align-items: center; text-align: center; vertical-align: central">
              <dxi-item class="rect" [baseSize]="60" style="align-content:center; align-items:center; align-self:center; text-align:center; vertical-align:middle">


                {{votazione?.Title}}
              </dxi-item>
              <dxi-item [ratio]="1" style="text-align: left; padding:0 10px 0 20px;" [ngClass]="votazione.PollDetails.length>8 ? 'listLong' : 'listSmall'">
                <dx-list class="scelta-preferenza" [dataSource]="votazione.PollDetails"
                         style="align-content: center; align-items: center; align-self: center; vertical-align: middle"
                         displayExpr="Descr" pageLoadMode="scrollBottom" keyExpr="PDID"
                         selectionMode="multiple"
                         [showSelectionControls]="true"
                         [(selectedItemKeys)]="pdChecked"
                         [scrollByContent]="true"
                         [scrollByThumb]="true"
                         showScrollbar="always"
                         height="100%"
                         (onSelectionChanged)="PollDetailsListSelection($event)">

                  <div *dxTemplate="let item of 'item'" style="font-size: 20px; padding: 5px; margin: 0; align-content: center; align-items: center; align-self: center;  vertical-align: middle">
                    <span style="white-space: normal; word-break: break-word;"
                          [class.text-bold]="item.IsSpecial" [class.text-italic]="item.IsNewPreferenceRequest"
                          [class.text-external]="item.AddedOnTheFly">{{item.Descr}}</span>
                  </div>

                </dx-list>
                <!--<dx-data-grid class="scelta-preferenza"
                      [dataSource]="votazione.PollDetails"
                      [rowAlternationEnabled]="true"
                      [showBorders]="true"
                      [showColumnHeaders]="false"
                      height="100%"
                      (onEditorPreparing)="PollDetailsEditorPreparing($event)">
          <dxo-scrolling mode="virtual"
                         [scrollByContent]="true"
                         [scrollByThumb]="true"
                         showScrollbar="always"></dxo-scrolling>

          <dxo-editing mode="cell"
                       [allowAdding]="false"
                       [allowDeleting]="false"
                       [allowUpdating]="true"></dxo-editing>

          <dxi-column dataField="IsChecked" [allowEditing]="true"></dxi-column>
          <dxi-column dataField="Descr" cellTemplate="DescrCellTemplate" [allowEditing]="false"></dxi-column>
          <div *dxTemplate="let d of 'DescrCellTemplate'" style="font-size: 20px; padding: 5px; margin: 0; ">
            <span [class.text-bold]="d.data.IsSpecial" [class.text-italic]="d.data.IsNewPreferenceRequest" [class.text-external]="d.data.AddedOnTheFly">{{d.data.Descr}}</span>
          </div>
        </dx-data-grid>-->
              </dxi-item>


              <dxi-item class="rect" [baseSize]="100" *ngIf="(!votoDisgiuntoInCorso && SharesEnabled) || (!votoDisgiuntoInCorso && ShareholderEnabled) || (votoDisgiuntoInCorso)">


                <dx-box direction="row" width="100%" height="100%">

                  <dxi-item *ngIf="(!votoDisgiuntoInCorso && ShareholderEnabled) || (votoDisgiuntoInCorso)" [baseSize]="200" style="border-top: 2px groove black; border-right: 2px groove black;">
                    <div *ngIf="!votoDisgiuntoInCorso && ShareholderEnabled" style="text-align: center; vertical-align: middle; font-size: 22px; margin: 10px; height: 100%">
                      <b>
                        Totale Soci<br />
                        <span class="label-green">{{GetNumeroSociPerPEID(votazione.PEID)}}</span>
                      </b>
                    </div>
                    <div *ngIf="votoDisgiuntoInCorso" style="text-align: center; vertical-align: middle; font-size: 22px; margin: 10px; height: 100%">
                      <b>
                        Voto<br />Disgiunto
                      </b>
                    </div>

                  </dxi-item>
                  <dxi-item [ratio]="1">
                    &nbsp;
                  </dxi-item>
                  <dxi-item *ngIf="(!votoDisgiuntoInCorso && SharesEnabled) || (votoDisgiuntoInCorso)" [baseSize]="250" style="border-top: 2px groove black; border-left: 2px groove black; ">
                    <div *ngIf="!votoDisgiuntoInCorso && SharesEnabled" style="text-align:center; vertical-align:middle; align-content:center; align-items:center; align-self:center; font-size:22px; margin:10px; height:100%">
                      <b>
                        {{SharesLabel}}<br />
                        <span class="label-orange">{{GetNumeroAzioniPerPEID(votazione.PEID)}}</span>
                      </b>
                    </div>
                    <div *ngIf="votoDisgiuntoInCorso" style="font-size:22px; margin:10px; height:100%">
                      <b>
                        Vota per<br />
                        <span class="label-orange">{{CurrentAccounting?.vShareholder?.BusinessName}}</span>
                      </b>
                    </div>
                  </dxi-item>




                </dx-box>


              </dxi-item>
              <dxi-item class="rect" baseSize="auto" style="align-content:center; align-items:center; align-self:center; text-align:center; vertical-align:middle; background-color:black;
margin:0; padding:3px">

                <span style="color:yellow; font-size:16px">{{votazione?.AllowMultipleItem ? votazione?.MultipleItemMaxNum == 1 ? "Una sola preferenza!": "Sono possibili "+ votazione?.MultipleItemMaxNum +" preferenze!" : "Una sola preferenza!"}}</span>
              </dxi-item>


            </dx-box>

          </div>

          <dx-box direction="col" width="85%" height="100%" class="stepFine" *ngIf="Votazioni.length>0 &&  actualStep>1 && actualStep==totalStep"
                  style="padding: 0 15px 15px 15px; align-self: center; ">
            <dxi-item baseSize="auto">
              <span style="padding: 10px; font-size:24px; border-radius: 10% 10% 10% 10%;">RIEPILOGO VOTO</span>
            </dxi-item>
              <dxi-item [ratio]="1" class="rect">


                <div style="overflow: auto; align-content: center; text-align: center; align-items: center; align-self: center; vertical-align: middle; height: 100%; width: 100%; background-color: #FFF">

                  <div *ngFor="let item of riepilogoVoto" style="display:inline; height:100%">
                    <dx-box direction="row" width="100%" height="97%">
                      <dxi-item baseSize="auto" class="rect" style="margin-top:50px; padding: 0 25px">
                        <table>
                          <tr *ngFor="let account of item?.votanti">
                            <td>
                              <span style="font-size:20px; margin-right:15px">{{account?.BusinessName}}</span>
                              <div *ngIf="ShowZoneMessage" style="margin:0;">
                                <span style="font-size:16px; margin-right:15px; font-weight:normal">
                                  Assemblea: {{account?.DelegationZone}}
                                </span>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </dxi-item>

                      <dxi-item baseSize="auto">
                        <ul class="containerResultList" style="display: flex; list-style: none; flex-direction: column; flex-wrap: wrap; max-height: 100% ; height:100%; margin:4px; padding: 4px; font-size:20px;">
                          <li *ngFor="let votazione of item?.votazioni" style="text-align:left; margin:5px; max-height:70%">
                            <div style="border-bottom: 2px solid blue; text-align: left;"><span style="margin-right: 15px;">{{votazione?.Title}}</span></div>
                            <ul class="containerResultList" style="display:flex; list-style:none; flex-direction:column; flex-wrap:wrap; max-height:90%; margin:4px; padding:4px">
                              <li *ngFor="let voto of votazione?.PollDetails" style="text-align: left; margin: 8px">
                                <img style="vertical-align:middle" src="../../../assets/images/chkbox.png">
                                <span style="margin-left: 5px">{{voto?.Descr}}</span>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </dxi-item>


                    </dx-box>

                  </div>

                  




                  <!--<table height="100%" align="center" style="max-height: 100%">
                    <tr>
                      <td *ngFor="let item of riepilogoVoto" style="padding: 10px 10px; background-color: #FFF; max-height: 100%" height="100%">
                        <table style="padding: 20px 25px; max-height: 100%" height="100%">
                          <tr>
                            <td align="left" style="border-right:1px solid blue;">
                              <table>
                                <tr *ngFor="let account of item?.votanti">
                                  <td>
                                    <span style="font-size:16px; margin-right:15px">{{account?.BusinessName}}</span>
                                    <div *ngIf="ShowZoneMessage" style="margin:0;">
                                      <span style="font-size:14px; margin-right:15px; font-weight:normal">
                                        Assemblea: {{account?.DelegationZone}}
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </table>
                            </td>
                            <td height="100%" style="max-height:100%">
                              <ul class="containerResultList" style="display: flex; list-style: none; flex-direction: column; flex-wrap: wrap; max-height: 100% ; margin:4px; padding: 4px">
                                <li *ngFor="let votazione of item?.votazioni" style="text-align:left; margin-right:3px; max-height:100%">-->
                                  <!--<div style="border-bottom: 2px solid blue; text-align: left;"><span style="font-size: 16px; margin-right: 15px;">{{votazione?.Title}}</span></div>-->
                                  <!--<ul class="containerResultList" style="display:flex; list-style:none; flex-direction:column; flex-wrap:wrap; max-height:100%; margin:4px; padding:4px">
                                    <li *ngFor="let voto of votazione?.PollDetails" style="text-align:left; margin-right:3px">
                                      <img style="vertical-align:middle" src="../../../assets/images/chkbox.png">
                                      <span style="font-size: 16px; margin-left: 5px">{{voto?.Descr}}</span>
                                    </li>
                                  </ul>
                                </li>
                              </ul>

                            </td>
                          </tr>
                        </table>


                      </td>
                    </tr>
                  </table>-->
                </div>



              </dxi-item>

          </dx-box>





        </dxi-item>
        <dxi-item [baseSize]="25"> </dxi-item>
      </dx-box>
    </dxi-item>
    <dxi-item class="rect" [baseSize]="90">

      <dx-box direction="row" width="100%" height="100%">

        <dxi-item [ratio]="1">
          <div style="text-align: left; margin: 20px 10px 0 10px;">
            <dx-button #annullaButton (click)="onClickedAnnulla($event)" [visible]="annullaButtonVisible" height="50" min-width="120"
                       style=" border-radius: 3px; padding: 3px 5px; margin: 0 10px; font-size: 18px; vertical-align: middle; "
                       class="movement-button-little-red" text='{{"LABEL_ANNULLA_POLL_BOOTH" | formatMessage}}'>
            </dx-button>

            <dx-button #indietroButton (click)="onClickedIndietro($event)" [visible]="indietroButtonVisible" height="50" min-width="120"
                       style=" border-radius: 3px; padding: 3px 5px; margin: 0 10px; font-size: 18px; vertical-align: middle; "
                       class="movement-button-little-blue" text='{{"LABEL_INDIETRO_POLL_BOOTH" | formatMessage}}'>
            </dx-button>

            <dx-button #disgiuntoButton (click)="onClickedDisgiunto($event)" [visible]="disgiuntoButtonVisible" height="50" min-width="120"
                       style=" border-radius: 3px; padding: 3px 5px; margin: 0 10px; font-size: 18px; vertical-align: middle; "
                       class="movement-button-little-yellow" text='{{"LABEL_DISGIUNTO_POLL_BOOTH" | formatMessage}}'>
            </dx-button>


          </div>
        </dxi-item>
        <!--<dxi-item baseSize="auto">

          <div style="text-align: center; height: 100%; vertical-align: middle ">
            <img src="../../../assets/images/xdatanet_positivo.png" alt="XDATANET" />
          </div>

        </dxi-item>-->


        <dxi-item [ratio]="1">
          <div style="text-align:center; margin:5px 10px;">
            <dx-button #confirmButton (click)="onClickedConferma($event)" [visible]="confermaButtonVisible" height="80" min-width="150"
                       template="buttonTemplate" icon="../../../assets/images/poll/ConfirmVote.png"
                       style=" border-radius: 3px; padding: 3px 5px; margin: 0 10px; font-size: 18px; vertical-align: middle; "
                       class="movement-button-little-green" text='{{"LABEL_CONFERMA_POLL_BOOTH" | formatMessage}}'>
              <div *dxTemplate="let data of 'buttonTemplate'">
                <table *ngIf="!ShowZoneMessage" style="margin:5px;" height="55">
                  <tr>
                    <td rowspan="3" align="center" valign="middle">
                      <img style=" margin:0 10px " src="{{data.icon}}" width="50" height="50" />
                    </td>
                    <td align="left" style="font-size:20px" valign="top">{{data.text}}</td>
                  </tr>
                  <tr>
                    <td align="left" style="font-size:14px;">Premere qui per registrare il voto, e</td>
                  </tr>
                  <tr>
                    <td align="left" style="font-size:14px;">concludere</td>
                  </tr>
                </table>

                <table *ngIf="ShowZoneMessage" style="margin:5px;" height="55">
                  <tr>
                    <td rowspan="3" align="center" valign="middle">
                      <img style=" margin:0 10px " src="{{data.icon}}" width="50" height="50" />
                    </td>
                    <td align="left" style="font-size:20px" valign="top">{{data.text}}</td>
                  </tr>
                  <tr>
                    <td align="left" style="font-size:14px;">Premere qui per SOTTOSCRIVERE E CONFERMARE</td>
                  </tr>
                  <tr>
                    <td align="left" style="font-size:14px;">i voti espressi</td>
                  </tr>
                </table>

              </div>
            </dx-button>

          </div>
        </dxi-item>

        <dxi-item [ratio]="1">
          <div style="text-align: right; margin: 20px 10px 0 10px;">

            <dx-button #goButton (click)="onClickedGo($event)" [visible]="goButtonVisible" height="50" min-width="120"
                       style=" border-radius: 3px; padding: 3px 5px; margin:0 10px; font-size: 18px; vertical-align: middle; "
                       class="movement-button-little-green" text='{{"LABEL_GO_POLL_BOOTH" | formatMessage}}'>
            </dx-button>

            <dx-button #annullaVotoButton (click)="onClickedAnnullaVoto($event)" [visible]="annullaVotoButtonVisible" height="50" min-width="120"
                       style=" border-radius: 3px; padding: 3px 5px; margin: 0 10px; font-size: 18px; vertical-align: middle; "
                       class="movement-button-little-red" text='{{"LABEL_ANNULLA_VOTO_POLL_BOOTH" | formatMessage}}'>
            </dx-button>
          </div>
        </dxi-item>
      </dx-box>






    </dxi-item>
  </dx-box>
</div>
