import { Component, NgModule, ViewChild } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ApplicationPipesModule } from '../../../pipes/application-pipes.module';
import { CommonModule } from '@angular/common';
import { DxDataGridModule, DxDataGridComponent, DxButtonModule } from 'devextreme-angular';
import { AssembleaService } from '../../../services/assemblea.service';
import { AssembleaError } from '../../../models/assemblea/AssembleaError';
import { MatDialog } from '@angular/material/dialog';
import { isNullOrWhiteSpace, isNullOrUndefined } from '../../../utils/utils';
import * as _ from 'lodash';
import { formatMessage } from 'devextreme/localization';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { vShareholders } from '../../../models/assemblea/vShareholders';
import XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { ErrorService } from '../../../services/error.service';
import { ImportPrincipalRequest } from '../../../models/request/ImportPrincipalRequest';
import { Principal } from '../../../models/assemblea/Principal';

type AOA = any[][];

@Component({
    selector: 'app-test-import-principal',
    templateUrl: './test-import-principal.component.html',
    styleUrls: ['./test-import-principal.component.scss']
})
/** test-import-principal component*/
export class TestImportPrincipalComponent {

  /*visualizza header du più righe*/
  titleHeaderTemplate(header, info) {
    $("<div>").html(info.column.caption.replace(/\r\n/g, "<br/>")).appendTo(header);

  }


  public errors: string[] = [];
  public messages: string[] = [];

  phonePattern = "^[0-9]{0,12}$";
  countryPattern = "^[a-zA-Z]{2}$";
  emailRegularExpression: RegExp = new RegExp("^[a-z0-9_\\+-]+(\\.[a-z0-9_\\+-]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*\\.([a-z]{2,4})$");
  phoneRegularExpression: RegExp = new RegExp("^[0-9]{0,12}$");

  LanguagesList: any[] = [
    { id: 'it-IT', descr: 'Italiano' },
    { id: 'en-US', descr: 'Inglese' },
    { id: 'de-DE', descr: 'Tedesco' },
    { id: 'es-ES', descr: 'Spagnolo' }
  ];
  InError: boolean = false;
  ScrollToNew: boolean = false;

  principal: { username: string, name: string, pwd: string, rid:number }[] = [];



  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;

  constructor(private assembleaService: AssembleaService, private toasterService: ToastrService
    , private dialog: MatDialog, private ngxService: NgxUiLoaderService, private errorService: ErrorService) {

    this.ngxService.start();

    this.ImportPrincipal = this.ImportPrincipal.bind(this);
    this.ngxService.stop();
  }


  public loadFilePrincipal(e) {
    this.errors = [];
    this.messages = [];
    let file = e.target.files[0];
    if (file === null) return;
    this.ngxService.start();
    this.caricaFileXlsx(file).then(() => { e.srcElement.value = null; }).finally(() => { this.ngxService.stop(); });
  }


  private async caricaFileXlsx(file) {

    var reader = new FileReader();
    var that = this;
    // Closure to capture the file information.

    reader.onload = async (e: any) => {

      let firstSkipped = false;

      that.principal = [];

      try {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const lines = <AOA>(XLSX.utils.sheet_to_json(ws, {
          header: 1
        }));

        for (let element of lines) {
          if (firstSkipped) {
            let cols = element;
            if (cols.length > 0) {

              let username: string = '';
              let name: string = '';
              let pwd: string = '';
              let rid: number;

              if (isNullOrUndefined(cols[0]) || isNullOrWhiteSpace(cols[0] + '')) {
                const msg = "Il campo 'username' dev'essere indicato per tutte le righe";
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              } else {
                username = <string>cols[0];

              }

              if (isNullOrUndefined(cols[1]) || isNullOrWhiteSpace(cols[1] + '')) {
                const msg = "Il campo 'nome' dev'essere indicato per tutte le righe";
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              } else {
                name =  <string>cols[1];

              }

              if (isNullOrUndefined(cols[2]) || isNullOrWhiteSpace(cols[2] + '')) {
                const msg = "Il campo 'pwd' dev'essere indicato per tutte le righe";
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              } else {
                pwd = <string>cols[2];

              }

              if (isNullOrUndefined(cols[3]) || isNullOrWhiteSpace(cols[3] + '')) {
                const msg = "Il campo 'ruolo' dev'essere indicato per tutte le righe";
                if (isNullOrUndefined(that.errors.find(i => i == msg))) {
                  that.errors.push(msg);
                }
              } else {
                rid = Number(cols[3] + '');

              }

              if (!isNullOrUndefined(username) && !isNullOrUndefined(name) && !isNullOrUndefined(pwd)) {

                try {
                  let request: ImportPrincipalRequest =
                    new ImportPrincipalRequest({ pUsername: username, pName: name, pPwd:pwd, pRoleID: rid, pIsPreview: true });
                  let result = (await this.assembleaService.importPrincipal([request]))[0];

                  console.log({ 'imported': result });

                  //if (!isNullOrWhiteSpace(result.Error)) {
                  //  this.errors.push(`Errore in  '${username}/${name}' - ${result.Error}`);
                  //}
                } catch (e) {

                  if ('IsAssembleaException' in e) {
                    let error: AssembleaError = <AssembleaError>e;
                    that.errors.push(`Errore in utente '${username}/${name}' - ${error.Detail.Message}`);
                  }
                  else {
                    that.errors.push(e.message);
                  }

                }

                that.principal.push({ username: username, name: name, pwd:pwd, rid:rid });

              }
            }
            if (that.errors.length > 8) {
              that.errors.push('.....');
              that.errors.push('.....');
              break;
            }
          } else {
            firstSkipped = true;
          }
        }

        if (that.errors.length > 0) {
          this.toasterService.error(formatMessage("TEXT_ERROR_PRESENT", ""), '');
        }
      } catch (e) {
        this.errorService.showErrorMessage(e);
      }

    };

    try {
      reader.readAsBinaryString(file);
    } catch (e) {
      this.errorService.showErrorMessage(e);
    }

  }


  async ImportPrincipal() {


    this.ngxService.start();
    let imported: number = 0;

    try {

      let request: ImportPrincipalRequest[] = [];
      for (let element of this.principal) {
        request.push(new ImportPrincipalRequest({ pUsername: element.username, pName: element.name, pPwd:element.pwd, pRoleID:element.rid, pIsPreview: false }));
      }

      let response: Principal[] = await this.assembleaService.importPrincipal(request);

      for (let element of response) {

          imported++;
     

      }

      this.toasterService.success(`Sono state correttamente importate ${imported} righe su ${this.principal.length}.`);

    } catch (e) {

      this.errorService.showErrorMessage(e, "Errore nell'importazione dei principal");

      if (imported > 0) {
        this.toasterService.warning(`Sono state correttamente importate ${imported} righe su ${this.principal.length}.`);
      }
      if ('IsAssembleaException' in e) {
        let error: AssembleaError = <AssembleaError>e;
        this.errors.push(formatMessage(error.Code, "") + " - " + error.Detail.detail.Message);

      }
      else {
        this.errors.push(e.error.MessageDetail);
      }
    }
    finally {
      this.messages.push(`Sono state importate ${imported} righe su ${this.principal.length}.`);
      this.ngxService.stop();
    }

  }


}
